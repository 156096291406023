<template>
	<div class="legal">
	
	<h3 class="slogan" v-html="$t('legal.title')"></h3>

	<!-- 1 -->
	<h4 v-html="$t('legal.sections.s1.title')"></h4>

	<div v-html="$t('legal.sections.s1.p')"></div>
	
	<!-- 2 -->
	<h4 v-html="$t('legal.sections.s2.title')"></h4>

	<div v-html="$t('legal.sections.s2.p')"></div>

	<!-- 3 -->
	<h4 v-html="$t('legal.sections.s3.title')"></h4>

	<div v-html="$t('legal.sections.s3.p')"></div>

	<!-- 4 -->

	<h4 v-html="$t('legal.sections.s4.title')"></h4>

	<div v-html="$t('legal.sections.s4.p')"></div>
	<!-- 5 -->

	<h4 v-html="$t('legal.sections.s5.title')"></h4>

	<div v-html="$t('legal.sections.s5.p')"></div>

	<!-- 6 -->

	<h4 v-html="$t('legal.sections.s6.title')"></h4>

	<div v-html="$t('legal.sections.s6.p')"></div>

	<!-- 7 -->

	<h4 v-html="$t('legal.sections.s7.title')"></h4>

	<div v-html="$t('legal.sections.s7.p')"></div>

  </div>

</template>

<script>

	export default {
		name: 'Legal'
	}

</script>

<style lang="scss">

	.legal
	{
		padding:40px;
		width: calc(100% - 80px);
		max-width: 1133px;
		margin:70px auto;
		text-align: justify;
		color:$primary-color;

		.slogan
		{
			font-style: normal;
			font-weight: 400;
			font-size: 25px;
			line-height: 25px;
			color:$primary-color;
			margin:0 0 35px 0;
			text-align: center;

			@include respond-above(md)
			{
				display: block;
				
			}

		}

		h4
		{
			margin-top: 40px;
		}

		*
		{
			font-style: normal;
			font-weight: regular;
			font-size: 18px;
			line-height: 20px;
			color:$primary-color;
		}

		table{
			tr
			{
				td, th
				{
					padding:10px;
				}
			}
		}
	}

</style>
